import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Image, Section, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Juna& Glate
			</title>
			<meta name={"description"} content={"Відкрийте для себе неприборканий дух мотоциклів завдяки унікальній колекції мотоциклів та навчанню, яке не схоже на звичайне."} />
			<meta property={"og:title"} content={"Головна | Juna& Glate"} />
			<meta property={"og:description"} content={"Відкрийте для себе неприборканий дух мотоциклів завдяки унікальній колекції мотоциклів та навчанню, яке не схоже на звичайне."} />
			<meta property={"og:image"} content={"https://junaandglate.com/pics/contact.jpg"} />
			<link rel={"shortcut icon"} href={"https://junaandglate.com/pics/1036184.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://junaandglate.com/pics/1036184.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://junaandglate.com/pics/1036184.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://junaandglate.com/pics/1036184.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://junaandglate.com/pics/1036184.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="normal 900 48px/1.2 --fontFamily-sans"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Juna& Glate - оренда молоциклів, скутерів, квадроциклів
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Відкрийте для себе неприборканий дух мотоциклів завдяки унікальній колекції мотоциклів та навчанню, яке не схоже на звичайне.
				</Text>
				<Link
					href="/contact-us"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="#3333c5"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Забронювати
				</Link>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://junaandglate.com/pics/image 54.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 20px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				lg-width="100%"
				md-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					padding="0px 16px 0px 0px"
				>
					Наша колекція мотоциклів{" "}
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="28px 0px 16px 16px"
				lg-width="100%"
				md-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 0px 0px" color="--darkL1" font="--lead">
					У Juna& Glate кожен мотоцикл розповідає свою історію. Ось деякі з них, які ми підібрали для вас:
					<br />
					<br />
					<Strong>
						Шукачі пригод
					</Strong>
					{" "}- долайте пересічену місцевість та досліджуйте незвідані шляхи з нашою міцною лінійкою.
					<br />
					<br />
					<Strong>
						Speed Spectres
					</Strong>
					{" "}- для тих, кому потрібна швидкість, ці велосипеди обіцяють адреналін у кожній поїздці.
					<br />
					<br />
					<Strong>
						Urban Unwind
					</Strong>
					{" "}- Ідеальні для міських прогулянок, ці велосипеди поєднують в собі комфорт та стиль.
				</Text>
			</Box>
			<Box
				display="grid"
				width="100%"
				margin="80px 0px 0px 0px"
				lg-margin="40px 0px 0px 0px"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="32px"
				sm-grid-template-columns="1fr"
			>
				<Image
					src="https://junaandglate.com/pics/ytf4vqbbnlr96njo42ns.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					grid-column="1 / span 1"
					border-radius="24px"
				/>
				<Image
					src="https://junaandglate.com/pics/1599749575.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					grid-column="2 / span 2"
					border-radius="24px"
					sm-grid-column="1 / span 1"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" background="#EDF2F6" sm-padding="60px 0 60px 0">
			<Box
				width="100%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				lg-width="100%"
				margin="0px 0px 56px 0px"
				align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Приєднуйтесь до спільноти Juna& Glate
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--greyD3"
					md-text-align="center"
					text-align="center"
					max-width="780px"
				>
					Станьте частиною згуртованої спільноти, де ви зможете ділитися своїми поїздками, досвідом та історіями. Приєднуйтесь до щомісячних зустрічей, поїздок на вихідні та майстер-класів, щоб розпалити свою пристрасть.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});